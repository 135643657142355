import { merge } from 'lodash';
import { useSessionStorage } from 'usehooks-ts';

import {
  FunnelEngineConfig,
  FunnelEngineContext,
  FunnelEngineData,
  IFunnelEngineHookOptions,
  IFunnelNavigation,
} from './../types';
import { createEmptyData, stringToRoute } from './helpers';

export const useFunnelSession = <T extends FunnelEngineConfig>(name: string, options: IFunnelEngineHookOptions<T>) => {
  const { config, initialContext, initialRoute, initialData } = options;
  const firstSection = Object.keys(config)[0];
  const firstPage = Object.keys(config[firstSection])[0];

  const _initialRoute = stringToRoute<T>(initialRoute, config) || {
    section: firstSection,
    page: firstPage,
  };

  const [data, setData] = useSessionStorage<FunnelEngineData<T>>(
    `funnel-${name}-data`,
    merge(createEmptyData(config), initialData),
  );

  const [context, setContext] = useSessionStorage<FunnelEngineContext<T>>(`funnel-${name}-context`, initialContext);

  const [navigation, setNavigation] = useSessionStorage<IFunnelNavigation<T>>(`funnel-${name}-navigation`, {
    navigationStack: [],
    currentRoute: _initialRoute,
  });

  return {
    data,
    setData,
    context,
    setContext,
    navigation,
    setNavigation,
  };
};
