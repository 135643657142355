import { captureException } from '@sentry/react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { routeToString } from './helpers';
import { useFunnelLoading } from './useFunnelLoading';
import { useFunnelNavigation } from './useFunnelNavigation';
import { useFunnelSession } from './useFunnelSession';
import { IFunnelEngineHookOptions } from '..';
import { showErrorNotification } from '../../showNotification';
import {
  FunnelDebugApi,
  FunnelEngineActions,
  FunnelEngineConfig,
  FunnelEngineContext,
  FunnelEngineErrors,
  FunnelPageConfiguration,
  FunnelPageData,
  FunnelRoute,
  FunnelRoutes,
  IFunnelApi,
  IFunnelNavigation,
} from '../types';

export const useFunnelInstance = <T extends FunnelEngineConfig>(name: string, options: IFunnelEngineHookOptions<T>) => {
  const { config, replay, handlers, initialLoader } = options;

  const { data, setData, context, setContext, navigation, setNavigation } = useFunnelSession(name, options);

  const replayInProgressRef = useRef(false);
  const funnelApiRef = useRef<
    IFunnelApi<FunnelPageData<T>, FunnelEngineContext<T>, FunnelEngineActions<T>, FunnelEngineErrors<T>>
  >({
    state: { hasPreviousPage: false },
    updateContext: (updatedFields) => {
      contextRef.current = Object.assign({}, context, updatedFields);
    },
  } as IFunnelApi<FunnelPageData<T>, FunnelEngineContext<T>, FunnelEngineActions<T>, FunnelEngineErrors<T>>);

  const [funnelApi, setFunnelApi] = useState<
    IFunnelApi<FunnelPageData<T>, FunnelEngineContext<T>, FunnelEngineActions<T>, FunnelEngineErrors<T>>
  >(funnelApiRef.current);

  const currentPageErrorRef = useRef<FunnelEngineErrors<T>>();

  const { currentRoute } = navigation;

  const onNavigate = useCallback(
    (previousPage: FunnelRoutes<T>, nextPage: FunnelRoutes<T>) =>
      handlers?.onNavigate?.({
        previousPage,
        page: nextPage,
        context: contextRef.current,
        data,
      }),
    [handlers, data],
  );

  const { currentPageConfig, goBack, canGoBack, goForwardTo, getNextRoute } = useFunnelNavigation(
    options,
    navigation,
    setNavigation,
    onNavigate,
  );

  const { isLoading, currentLoader, toggleLoader, withLoading, currentLoadTimeMs } = useFunnelLoading(
    options,
    currentPageConfig,
    !!initialLoader,
  );

  // we use ref for having the immediate value available
  const contextRef = useRef(context);
  // ref will be synced to session
  useEffect(() => setContext(contextRef.current), [setContext, contextRef.current]);

  const setCurrentData = useCallback(
    (data?: Partial<FunnelPageData<T>>) => {
      if (!data) return;
      setData((currentData) => ({
        ...currentData,
        [currentRoute.section]: {
          ...currentData[currentRoute.section],
          [currentRoute.page]: {
            ...(currentData[currentRoute.section][currentRoute.page] || {}),
            ...data,
          },
        },
      }));
    },
    [currentRoute, setData],
  );

  const handleException = useCallback(
    (err: Error, handler: string, route: string) => {
      showErrorNotification(err?.message);
      captureException(err, {
        extra: {
          handler,
          context: JSON.stringify(context, null, 4),
          data: JSON.stringify(data, null, 4),
          route: route,
        },
      });
      console.error(err);
    },
    [context, data],
  );

  const executePageEntry = useCallback(
    async (entryRoute: FunnelRoute<T>) => {
      const page = config[entryRoute.section][entryRoute.page];
      const pageData = data[entryRoute.section][entryRoute.page];
      try {
        const onEntryError = await page.onEntry?.({
          data: pageData,
          context: contextRef.current,
          funnelApi: funnelApiRef.current,
        });

        if (onEntryError) {
          currentPageErrorRef.current = onEntryError;
        }
      } catch (err) {
        handleException(err as Error, 'onEntry', routeToString(entryRoute));
      }

      if (page.terminal) {
        await handlers?.onComplete?.({
          page: routeToString(entryRoute),
          data,
          context: contextRef.current,
          isReplay: replayInProgressRef.current,
        });
      }

      if (entryRoute.section !== currentRoute?.section) {
        try {
          await handlers?.onSectionEntry?.({
            section: entryRoute.section,
            page: routeToString(entryRoute),
            data,
            context: contextRef.current,
            isReplay: replayInProgressRef.current,
          });
        } catch (err) {
          handleException(err as Error, 'onSectionEntry', routeToString(entryRoute));
        }
      }

      try {
        await handlers?.onPageEntry?.({
          page: routeToString(entryRoute),
          data,
          context: contextRef.current,
          isReplay: replayInProgressRef.current,
        });
      } catch (err) {
        handleException(err as Error, 'onPageEntry', routeToString(entryRoute));
      }
    },
    [data, handlers, config, handleException, currentRoute?.section],
  );
  const executePageExit = useCallback(
    async (
      page: FunnelPageConfiguration<
        FunnelPageData<T>,
        FunnelEngineContext<T>,
        FunnelEngineActions<T>,
        FunnelEngineErrors<T>
      >,
      pageData: FunnelPageData<T[typeof currentRoute.section][typeof currentRoute.page]>,
    ) => {
      try {
        const onExitError = await page.onExit?.({
          data: pageData,
          context: contextRef.current,
          funnelApi: funnelApiRef.current,
        });

        if (onExitError) {
          currentPageErrorRef.current = onExitError;
          return;
        }
      } catch (err) {
        handleException(err as Error, 'onExit', routeToString(currentRoute));
        return;
      }

      try {
        await handlers?.onPageExit?.({
          page: routeToString(currentRoute),
          data: {
            ...data,
            [currentRoute.section]: {
              ...data[currentRoute.section],
              [currentRoute.page]: pageData,
            },
          },
          context: contextRef.current,
          isReplay: replayInProgressRef.current,
        });
      } catch (err) {
        handleException(err as Error, 'onPageExit', routeToString(currentRoute));
      }
    },
    [data, handlers, currentRoute],
  );

  const initialLoadDoneRef = useRef(false);

  useEffect(() => {
    if (initialLoadDoneRef.current) return;
    if (!currentRoute) return;

    initialLoadDoneRef.current = true;

    const executeReplay = async () => {
      replayInProgressRef.current = true;
      const navigation: IFunnelNavigation<T> = {
        currentRoute,
        navigationStack: [currentRoute],
      };
      let pageData = data[currentRoute.section][currentRoute.page];

      const shouldContinue = () => {
        return (
          !!pageData &&
          Object.keys(pageData).length > 0 &&
          Object.keys(pageData).some((key) => pageData[key] !== undefined) &&
          config[navigation.currentRoute.section][navigation.currentRoute.page].terminal !== true
        );
      };

      while (shouldContinue()) {
        const { currentRoute } = navigation;
        await executePageExit(config[currentRoute.section][currentRoute.page], pageData);

        const { route: sectionAndPage, error } = getNextRoute(
          contextRef.current,
          pageData,
          currentPageErrorRef.current,
          currentRoute,
        );

        if (error) {
          currentPageErrorRef.current = error;
        }

        pageData = data[sectionAndPage.section][sectionAndPage.page];
        navigation.currentRoute = sectionAndPage;
        navigation.navigationStack.push(sectionAndPage);
      }

      setNavigation(navigation);
      await executePageEntry(navigation.currentRoute);
      replayInProgressRef.current = false;
    };

    void withLoading(
      async () => {
        if (replay && navigation.navigationStack.length === 0) {
          await executeReplay();
        } else {
          await executePageEntry(currentRoute);
        }
      },
      context,
      undefined,
      undefined,
      initialLoader,
    );
  }, [executePageEntry, initialLoader, withLoading, currentRoute, data, handlers, context]);

  const actions = useMemo(() => {
    if (!currentPageConfig.actions) return;

    const actions: FunnelEngineActions<T> = {} as FunnelEngineActions<T>;
    Object.keys(currentPageConfig.actions).forEach((actionName) => {
      const actionFn = currentPageConfig.actions[actionName];
      actions[actionName] = (...args: unknown[]) => {
        return actionFn(...args, {
          context: contextRef.current,
          funnelApi: funnelApiRef.current,
          data: data[currentRoute.section][currentRoute.page],
        });
      };
    });
    return actions;
  }, [currentPageConfig, currentRoute, data]);

  useEffect(() => {
    funnelApiRef.current = {
      state: {
        hasPreviousPage: canGoBack,
      },
      setContext: (context) => (contextRef.current = context),
      updateContext: (updatedFields) => {
        contextRef.current = Object.assign({}, contextRef.current, updatedFields);
      },
      back: () => {
        if (canGoBack) {
          goBack();
        }
      },
      next: (pageData) => {
        currentPageErrorRef.current = undefined;
        setCurrentData(pageData);

        void withLoading(
          async () => {
            await executePageExit(currentPageConfig, pageData);

            const { route: sectionAndPage, error } = getNextRoute(
              contextRef.current,
              pageData,
              currentPageErrorRef.current,
            );

            if (error) {
              currentPageErrorRef.current = error;
            }

            await executePageEntry(sectionAndPage);

            return sectionAndPage;
          },
          context,
          pageData,
          (sectionAndPage) => {
            if (sectionAndPage) {
              goForwardTo(sectionAndPage);
            }
          },
        );
      },
      update: setCurrentData,
      actions,
      error: currentPageErrorRef.current,
    };

    setFunnelApi(funnelApiRef.current);
  }, [
    currentPageErrorRef.current,
    actions,
    canGoBack,
    context,
    currentPageConfig,
    executePageEntry,
    executePageExit,
    getNextRoute,
    goBack,
    goForwardTo,
    setCurrentData,
    withLoading,
  ]);

  return {
    funnelApi,
    data,
    context: contextRef.current,
    isLoading,
    navigation,
    currentPageConfig,
    currentLoader,
    currentLoadTimeMs: currentLoadTimeMs.current,
    debugApi: {
      goForwardTo,
      resetFunnel: () => {
        sessionStorage.clear();
        location.reload();
      },
      executePageExit: (page) => {
        return executePageExit(options.config[page.section][page.page], data[page.section][page.page]);
      },
      toggleLoader: (page: FunnelRoute<T>) => {
        toggleLoader(options.config[page.section][page.page].loader, context, data[page.section][page.page]);
      },
      executePageEntry,
    } as FunnelDebugApi<T>,
  };
};
