import { ReactComponent as ArrowRight } from '@assets/icons/fi-rr-arrow-right.svg';
import { ReactComponent as LoaderIcon } from '@assets/icons/fi-rr-refresh.svg';
import { Button, ButtonGroup } from '@mantine/core';
import React, { useState } from 'react';

import { FunnelDebugApi, FunnelEngineConfig, FunnelRoute } from '..';

export const DebuggerPageNavigator = <T extends FunnelEngineConfig>({
  debugApi,
  route,
  currentRoute,
}: {
  debugApi: FunnelDebugApi<T>;
  route: FunnelRoute<T>;
  currentRoute: FunnelRoute<T>;
}) => {
  const [executingPageEntry, setExecutingPageEntry] = useState(false);
  const isCurrentRoute = route.section === currentRoute.section && route.page === currentRoute.page;

  return (
    <ButtonGroup>
      <Button
        disabled={executingPageEntry}
        onClick={async () => {
          setExecutingPageEntry(true);
          await debugApi.executePageEntry(route);
          debugApi.goForwardTo(route);
          setExecutingPageEntry(false);
        }}
        color="cream.4"
        size="xs"
        rightSection={<ArrowRight width={14} />}
      />
      <Button style={{ flex: 1 }} onClick={() => debugApi.goForwardTo(route)} size="xs" disabled={isCurrentRoute}>
        {String(route.page)}
      </Button>
      <Button
        disabled={executingPageEntry}
        onClick={() => debugApi.toggleLoader(route)}
        color="cream.4"
        size="xs"
        leftSection={<LoaderIcon width={14} />}
      />
    </ButtonGroup>
  );
};
