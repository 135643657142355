import { Group, Image, ImageProps, Stack } from '@mantine/core';
import { useTheme } from '@shared/theme';
import { FunctionComponent, PropsWithChildren } from 'react';

export const EmblaPageLayout: FunctionComponent<
  PropsWithChildren<{ noPadding?: boolean; heroImageSrc?: ImageProps['src'] }>
> = ({ children, noPadding, heroImageSrc }) => {
  const { theme } = useTheme();

  return (
    <Stack px={0} h="100vh">
      <Group gap={0} align="flex-start" wrap="nowrap">
        <Stack p="md" flex={1} align="center">
          <Group justify="flex-start" w="100%">
            <Image src={theme.other.theme.assets.Logo} />
          </Group>
          <Stack flex={1} pt={noPadding ? undefined : 'xl'} mx="xl" w={heroImageSrc ? 572 : '100%'}>
            {children}
          </Stack>
        </Stack>
        {heroImageSrc && (
          <Stack flex={1} h="100vh" style={{ overflow: 'hidden' }}>
            <Image style={{ flex: 1 }} src={heroImageSrc} />
          </Stack>
        )}
      </Group>
    </Stack>
  );
};
