import { NumberInput, NumberInputProps, Text } from '@mantine/core';
import { FocusEventHandler, FunctionComponent } from 'react';

interface IProps extends NumberInputProps {
  suffix?: string;
  suffixWidth?: number;
  selectOnFocus?: boolean;
  mode?: 'default' | 'measurement' | 'measurement-result' | 'website' | 'website-result';
}

export const EmblaNumberInput: FunctionComponent<IProps> = (props) => {
  const { suffix, selectOnFocus = true, mode = 'default', ...inputProps } = props;

  const selectAll: FocusEventHandler<HTMLInputElement> = (e) => {
    e.currentTarget?.setSelectionRange(0, e.currentTarget.value?.length || 0);
  };

  return (
    <NumberInput
      {...inputProps}
      w={mode === 'measurement' ? inputProps.width : undefined}
      data-mode={mode !== 'default' ? mode : undefined}
      min={0}
      hideControls
      onFocus={selectOnFocus ? selectAll : undefined}
      rightSection={<Text size={'md'}>{suffix}</Text>}
      rightSectionWidth={props.suffixWidth ?? 50}
    />
  );
};
