import { Notifications } from '@mantine/notifications';
import { i18n } from 'i18next';
import React, { FunctionComponent, PropsWithChildren } from 'react';
import { I18nextProvider } from 'react-i18next';

import { FeatureToggleContextProvider } from './FeatureToggleContext';
import classes from './notification.module.css';
import { ThemeContextProvider, ThemedMantineProvider } from './theme';
import { TrackingContextProvider } from './TrackingContext';

export const DefaultContexts: FunctionComponent<PropsWithChildren<{ i18n?: i18n }>> = ({ i18n, children }) => {
  return (
    <React.StrictMode>
      <TrackingContextProvider>
        <FeatureToggleContextProvider>
          <ThemeContextProvider>
            <ThemedMantineProvider>
              <I18nextProvider i18n={i18n}>
                <Notifications position="bottom-right" classNames={{ notification: classes.notification }} />
                {children}
              </I18nextProvider>
            </ThemedMantineProvider>
          </ThemeContextProvider>
        </FeatureToggleContextProvider>
      </TrackingContextProvider>
    </React.StrictMode>
  );
};
