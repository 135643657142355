import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import {
  FunnelEngineConfig,
  FunnelEngineContext,
  FunnelEngineErrors,
  FunnelPageData,
  FunnelRoute,
  FunnelRoutes,
  FunnelRoutingRules,
  IFunnelEngineHookOptions,
  IFunnelNavigation,
  NavigationDirection,
  SetValue,
} from './../types';
import { getSectionAndPageByDirectionRaw, routeToString, stringToRoute } from './helpers';
const routesEqual = <T extends FunnelEngineConfig>(
  routeA: FunnelRoute<T>,
  routeB: FunnelRoute<T>,
): routeA is typeof routeB => {
  return routeA?.section === routeB?.section && routeA?.page === routeB?.page;
};

const useHash = () => {
  const [hash, setHash] = useState(window.location.hash?.substring(1) || '');

  useEffect(() => {
    const onChange = () => {
      setHash(window.location.hash?.substring(1) || '');
    };
    window.addEventListener('hashchange', onChange);

    return () => window.removeEventListener('hashchange', onChange);
  }, []);

  return hash;
};

export const useFunnelNavigation = <T extends FunnelEngineConfig>(
  options: IFunnelEngineHookOptions<T>,
  navigation: IFunnelNavigation<T>,
  setNavigation: SetValue<IFunnelNavigation<T>>,
  onNavigate: (previousPage: FunnelRoutes<T>, nextPage: FunnelRoutes<T>) => void,
) => {
  const { config, routingRules = {} as FunnelRoutingRules<T>, initialRoute, disableHashRouting } = options;
  const { currentRoute, navigationStack } = navigation;
  const { section: currentSection, page: currentPage } = currentRoute;

  const hash = useHash();
  const navigationLockRef = useRef(false);

  const currentPageConfig = useMemo(() => config[currentSection][currentPage], [config, currentSection, currentPage]);

  const previousPageRef = useRef<FunnelRoutes<T>>();
  useEffect(() => {
    const newRoute = routeToString(currentRoute);
    if (newRoute !== previousPageRef.current) {
      onNavigate(previousPageRef.current, newRoute);
      previousPageRef.current = routeToString(currentRoute);
    }
  }, [currentRoute, onNavigate]);

  useEffect(() => {
    if (!disableHashRouting) {
      navigationLockRef.current = true;
      window.location.hash = routeToString(currentRoute);
    }
  }, [currentRoute, disableHashRouting]);
  useEffect(() => {
    if (navigationLockRef.current && hash === window.location.hash.substring(1)) {
      navigationLockRef.current = false;
    }
  }, [hash]);

  useEffect(() => {
    if (disableHashRouting) return;

    const route = stringToRoute(hash as FunnelRoutes<T>, config);
    if (!route || currentPageConfig.terminal) {
      window.location.hash = routeToString(currentRoute);
    } else if (!routesEqual(route, currentRoute)) {
      if (!navigationLockRef.current) {
        const backRoute = navigationStack[navigationStack.length - 2];
        if (routesEqual(backRoute, route)) {
          setNavigation({
            navigationStack: navigationStack.slice(0, navigationStack.length - 1),
            currentRoute: route,
          });
        } else {
          setNavigation({
            navigationStack: [...navigationStack, route],
            currentRoute: route,
          });
        }
      }
    }
  }, [disableHashRouting, hash, currentRoute, config, navigationStack, setNavigation, currentPageConfig]);

  const getSectionAndPageByDirection = useCallback(
    (direction: NavigationDirection, overrideCurrent?: { section: keyof T; page: keyof T[keyof T] }) =>
      getSectionAndPageByDirectionRaw(
        config,
        overrideCurrent?.section ?? currentSection,
        overrideCurrent?.page ?? currentPage,
        direction,
      ),
    [config, currentSection, currentPage],
  );

  const getNextRoute = useCallback(
    (
      context: FunnelEngineContext<T>,
      data?: FunnelPageData<T>,
      error?: FunnelEngineErrors<T>,
      overrideCurrent?: { section: keyof T; page: keyof T[keyof T] },
    ) => {
      let routedTo = getSectionAndPageByDirection(NavigationDirection.Next, overrideCurrent);
      let routeError = undefined;

      const _currentSection = overrideCurrent?.section ?? currentSection;
      const _currentPage = overrideCurrent?.page ?? currentPage;

      const routingRule = routingRules[_currentSection]?.[_currentPage];

      if (routingRule) {
        const routeResult = routingRule({ data, context, error });
        if (routeResult) {
          if (typeof routeResult === 'string') {
            routedTo = stringToRoute(routeResult, config);
          } else {
            routedTo = stringToRoute(routeResult.route, config);
            routeError = routeResult.error;
          }
        }
      }

      return { route: routedTo, error: routeError };
    },
    [currentSection, currentPage, routingRules, getSectionAndPageByDirection, config],
  );

  const goForwardTo = useCallback(
    (route: FunnelRoute<T>) => {
      setNavigation((navigation) => {
        const isLatestRoute = routesEqual(navigation.navigationStack[navigation.navigationStack.length - 1], route);
        const newStack = isLatestRoute ? [...navigation.navigationStack] : [...navigation.navigationStack, route];

        return {
          navigationStack: newStack,
          currentRoute: route,
        };
      });
    },
    [setNavigation],
  );

  useEffect(() => {
    const firstSection = Object.keys(config)[0];
    const firstPage = Object.keys(config[firstSection])[0];
    const fallbackRoute = { section: firstSection, page: firstPage };

    const _initialRoute = (initialRoute ? stringToRoute(initialRoute, config) : undefined) || fallbackRoute;

    if (navigationStack.length === 0) {
      goForwardTo(_initialRoute);
    }
  }, [goForwardTo, config, initialRoute, navigationStack]);

  const goBack = useCallback(() => {
    if (navigationStack.length < 2) return;

    const route = navigationStack[navigationStack.length - 2];

    if (routesEqual({ section: currentSection, page: currentPage }, route)) return;

    setNavigation({
      navigationStack: navigationStack.slice(0, navigationStack.length - 1),
      currentRoute: route,
    });
  }, [currentSection, currentPage, navigationStack, setNavigation]);

  return {
    currentPageConfig,
    canGoBack: navigationStack.length > 1 && !currentPageConfig.terminal,
    goBack,
    goForwardTo,
    getNextRoute,
  };
};
