import { Flex, Progress } from '@mantine/core';
import { useElementSize } from '@mantine/hooks';
import { ErrorBoundary } from '@sentry/react';
import React, { ReactNode } from 'react';

import { FunnelDebugger } from './internal/FunnelDebugger';
import { getFunnelSteps } from './internal/helpers';
import { useFunnelInstance } from './internal/useFunnelInstance';
import { FunnelEngineConfig, IFunnelEngineHookOptions } from './types';
import { EmblaContainer } from '../components/EmblaContainer';
import { EmblaTopContainer } from '../components/EmblaTopContainer';

export const Funnel = <T extends FunnelEngineConfig>({
  debug = false,
  name,
  options,
  sectionDisplay,
  footerDisplay,
}: {
  debug?: boolean;
  name: string;
  options: IFunnelEngineHookOptions<T>;
  sectionDisplay?: (sections: { label: keyof T; percentComplete: number }[]) => ReactNode;
  footerDisplay?: (sections: { label: keyof T; percentComplete: number }[]) => ReactNode;
}) => {
  const { config, defaultPageWidth } = options;
  const { ref: sectionDisplayRef, height: sectionDisplayHeight } = useElementSize();

  const {
    funnelApi,
    data,
    context,
    isLoading,
    navigation,
    currentPageConfig,
    currentLoader,
    currentLoadTimeMs,
    debugApi,
  } = useFunnelInstance(name, options);
  const { noContainer, noSectionDisplay } = currentPageConfig;
  const { section: currentSection, page: currentPage } = navigation.currentRoute;

  const pageWidth = currentPageConfig.pageWidth ?? defaultPageWidth;
  const pageRender = (
    <Flex
      style={{
        animationName: isLoading ? 'FadeOut' : 'FadeIn',
        animationDuration: '800ms',
        display: isLoading ? 'none' : 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        flex: 1,
      }}
      justify="flex-start"
    >
      <currentPageConfig.component data={data[currentSection][currentPage]} context={context} funnelApi={funnelApi} />
      {footerDisplay?.(getFunnelSteps(config, currentSection, currentPage))}
    </Flex>
  );

  return (
    <EmblaTopContainer noPadding fullWidth={noContainer}>
      {!noSectionDisplay && (
        <Flex my={16} ref={sectionDisplayRef}>
          {sectionDisplay?.(getFunnelSteps(config, currentSection, currentPage))}
        </Flex>
      )}
      <ErrorBoundary
        fallback={<div>Something went wrong...</div>}
        beforeCapture={(scope) => {
          scope.setExtras({
            currentSection,
            currentPage,
            data: JSON.stringify(data, null, 4),
            context: JSON.stringify(context, null, 4),
          });
        }}
      >
        {noContainer ? (
          pageRender
        ) : (
          <EmblaContainer style={{ alignSelf: 'center' }} w="100%" maw={pageWidth}>
            {pageRender}
          </EmblaContainer>
        )}
      </ErrorBoundary>
      <Flex
        style={{
          display: isLoading ? 'flex' : 'none',
          position: 'absolute',
          paddingLeft: 24,
          paddingRight: 24,
          paddingTop: sectionDisplayHeight,
          top: 0,
          left: 0,
          height: '100%',
          width: '100%',
          overflowX: 'hidden',
        }}
        align={'center'}
        justify="center"
      >
        <Flex
          style={{
            animationName: !isLoading ? 'FadeOut' : 'FadeIn',
            animationDuration: '800ms',
            maxWidth: 500,
            marginTop: currentLoader.offsetY ?? 0,
            marginLeft: currentLoader.offsetX ?? 0,
          }}
        >
          {isLoading ? <currentLoader.component /> : null}
        </Flex>
        {currentLoader.progressBar && (
          <Progress
            value={100}
            style={{
              position: 'absolute',
              left: -3,
              bottom: 0,
              width: 'calc(100% + 6px)',
              animationName: 'LoadingBarV3',
              animationDuration: `${currentLoadTimeMs}ms`,
            }}
            h={4}
          />
        )}
      </Flex>
      {debug && (
        <FunnelDebugger
          config={options.config}
          currentSection={currentSection}
          currentPage={currentPage}
          funnelApi={funnelApi}
          debugApi={debugApi}
          context={context}
          data={data}
          name={name}
        />
      )}
    </EmblaTopContainer>
  );
};
